import React from "react"
import { Redirect } from "react-router"
import Status from "../Base/Status"
// import Pricing from './components/Pricing'
// import Dashboard from './components/Dashboard'
import GameInstanceList from "./GameInstances/List"

const El = ({ location }) => {
  if (location.pathname.split("/").filter((e) => !!e).length < 2) {
    return (
      <Status code={302}>
        <Redirect to="/admin/game-instances" />
      </Status>
    )
  }
  return <GameInstanceList />
}

export default El
