import { Mutation, Query } from "@apollo/client/react/components"
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Fab from "@material-ui/core/Fab"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { Add as AddIcon } from "@material-ui/icons"
import { ApolloClient, ApolloError } from "@apollo/client"
import React, { Component } from "react"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
import { withoutDomain } from "../../../common/url-utils"
import { edgeToNode, unique } from "../../utils/misc-utils"
import { ButtonLink } from "../components/AdminLink"
import Layout from "../components/Layout"
import { ADMIN_DELETE_GAME_INSTANCE_MUTATION, ADMIN_GAME_INSTANCES_QUERY, ADMIN_SQL_QUERY, ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../queries"
import {
  actionParams,
  buildPeriodsFor,
  clearMpid,
  clearRowDbAttrs,
  getDefaultRoundBonusTypeFor,
  getPeriodLengthFor,
  getRoundBonusesFor,
} from "./New/helpers"

interface IQueryResult {
  loading: boolean
  error?: ApolloError | undefined
  data: any
  refetch: any
  client: any
}
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto" as const,
  },
  table: {
    minWidth: 700,
    whiteSpace: "nowrap" as const,
  },
  progress: {
    padding: theme.spacing(4),
  },
  fab: {
    position: "fixed" as const,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

function SimpleMenu({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={handleClick}>
        Actions
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </div>
  )
}

const sqlQuery = async (statement: string, client: ApolloClient<any>, ids?: string[]) => {
  const response = await client.query({
    query: ADMIN_SQL_QUERY,
    variables: { statement, ids },
  })
  return response.data.sql as any[]
}

// const getGameInstanceMappingsFor = async (gameInstanceId: string, client: ApolloClient<any>) => {
//   const gameInstances = (await sqlQuery(`SELECT * FROM GameInstance WHERE id = '${id}'`, client, [id]));
//   // NOTE qac: only get the latest season!
//   const seasons = await sqlQuery(`SELECT * FROM Season WHERE gameInstanceId = '${gameInstances[0].id}' ORDER BY createdAt DESC LIMIT 1`, client);
//   const segments = await sqlQuery(`SELECT * FROM Segment WHERE seasonId IN (${seasons.map((s) => s.id).join(", ")})`, client);
//   const periods = await sqlQuery(`SELECT * FROM Period WHERE segmentId IN (${segments.map((s) => s.id).join(", ")})`, client);
//   const mapping = {
//     gameInstances,
//     seasons,
//     segments,
//     periods,
//   };
//   return mapping
// }

const seasonToName = ({ year, seasonType }) => `${year} ${seasonType.toUpperCase().replace("ULAR", "")}`

interface IGameInstanceList extends RouteComponentProps {
  working?: boolean
  theme: any
  classes: any
}

class GameInstanceList extends Component<IGameInstanceList> {
  public state = {
    working: false,
  }
  public sportRenderValue = (selected) => typeof selected === "object" && selected.join(", ")

  public rolloverClick = (gameInstance: any, newSeason: any, client: ApolloClient<any>) => async (event) => {
    event.preventDefault()
    this.setState({ working: true })
    // console.dir(gameInstance);
    const allSeasons = gameInstance.seasons.edges.map(edgeToNode).sort((a, b) => b.year - a.year)
    const gameTypes = unique(
      allSeasons
        .map((ss) =>
          ss.segments.edges
            .map(edgeToNode)
            .map((seg) => seg.gameType)
            .join(","),
        )
        .join(",")
        .split(","),
    ) as string[]
    const currentSeason = gameInstance.currentPeriod && gameInstance.currentPeriod.segment.season
    const similarSeason = allSeasons.find((ss) => ss.season === newSeason.seasonType) || currentSeason
    const gameInstances = await sqlQuery(`SELECT * FROM GameInstance WHERE id = '${gameInstance.id}'`, client, [gameInstance.id])
    const seasons = await sqlQuery(`SELECT * FROM Season WHERE id = '${similarSeason.id}'`, client, [similarSeason.id])
    const segments = await sqlQuery(`SELECT * FROM Segment WHERE seasonId IN (${seasons.map((s) => s.id).join(", ")})`, client)
    if (gameTypes.length > 1 || gameTypes.length < 1) {
      // TODO qac: support obv
      window.alert(`TODO: multi gameTypes: ${gameTypes.join(", ")}`)
      this.setState({ working: false })
    } else if (gameTypes[0].toUpperCase() === "PICKEM") {
      const whereClause = [`sportType = '${newSeason.sportType}'`]
      whereClause.push(`seasonType = '${newSeason.seasonType}'`)
      whereClause.push(`year = ${newSeason.year}`)
      const events = await sqlQuery(
        `SELECT id, startsAt, sportType, weekNumber FROM Event WHERE ${whereClause.join(" AND ")} ORDER BY startsAt ASC LIMIT 300`,
        client,
      )
      const periods = buildPeriodsFor(events)
      this.setState({ working: false })
      // update attributes IF this is not a previously
      seasons.forEach((season) => {
        season.year = newSeason.year
        if (season.season !== newSeason.seasonType) {
          season.season = newSeason.seasonType
          season.productAbbrev = ""
        }
      })
      segments.forEach((seg) => {
        if (seg.seasonType !== newSeason.seasonType) {
          seg.poolSettings.periodLength = getPeriodLengthFor(newSeason.seasonType, seg.gameType, newSeason.sportType)
          seg.seasonType = newSeason.seasonType
          seg.poolSettings.roundBonusType = getDefaultRoundBonusTypeFor(newSeason.seasonType, seg.gameType, newSeason.sportType)
          seg.poolSettings.roundBonuses = getRoundBonusesFor(seg.poolSettings.roundBonusType, periods, seg.subsection)
        }
      })
      seasons.forEach(clearRowDbAttrs)
      segments.forEach(clearRowDbAttrs)
      const mapping = {
        gameInstances,
        seasons,
        segments,
        periods,
      }
      clearMpid(mapping.seasons)
      // console.dir(events);
      // console.dir(mapping);
      this.props.history.push(`/admin/game-instances/${gameInstance.id}/edit?${actionParams("ROLLOVER", mapping)}`)
    } else {
      // TODO qac: support obv
      window.alert(`TODO: BRACKET? gameTypes: ${gameTypes.join(", ")}`)
      this.setState({ working: false })
    }
  }

  public editClick = (id: string, seasonId: string, client: ApolloClient<any>) => async (event) => {
    event.preventDefault()
    this.setState({ working: true })
    const gameInstances = await sqlQuery(`SELECT * FROM GameInstance WHERE id = '${id}'`, client, [id])
    const seasons = await sqlQuery(`SELECT * FROM Season WHERE id = '${seasonId}'`, client, [seasonId])
    const segments = await sqlQuery(`SELECT * FROM Segment WHERE seasonId IN (${seasons.map((s) => s.id).join(", ")})`, client)
    const periods = await sqlQuery(`SELECT * FROM Period WHERE segmentId IN (${segments.map((s) => s.id).join(", ")})`, client)
    const mapping = {
      gameInstances,
      seasons,
      segments,
      periods,
    }
    this.props.history.push(`/admin/game-instances/${id}/edit?${actionParams("EDIT", mapping)}`)
  }

  public comingSoonClick = (isNotComingSoonState: boolean, currentPeriod: any, client: ApolloClient<any>, refetch: () => Promise<void>) => (
    event,
  ) => {
    event.preventDefault()
    this.setState({ working: true })
    // console.dir(gameInstance);
    const updates = {
      id: currentPeriod.id,
      isPickable: isNotComingSoonState ? false : true,
      isInManualMode: isNotComingSoonState ? true : false,
    }
    const mapping = {
      Period: updates,
    }
    return client
      .mutate({
        mutation: ADMIN_UPSERT_GAME_INSTANCE_MUTATION,
        variables: {
          mapping,
        },
      })
      .catch((err) => {
        window.alert(`Failed to update`)
      })
      .then(refetch)
      .then(() => this.setState({ working: false }))
  }

  public copyClick = (gameInstance: any, client: ApolloClient<any>) => async (event) => {
    event.preventDefault()
    this.setState({ working: true })
    const allSeasons = gameInstance.seasons.edges.map(edgeToNode)
    const season = (gameInstance.currentPeriod && gameInstance.currentPeriod.segment.season) || allSeasons[allSeasons.length - 1]
    const gameInstances = await sqlQuery(`SELECT * FROM GameInstance WHERE id = '${gameInstance.id}'`, client, [gameInstance.id])
    const seasons = await sqlQuery(`SELECT * FROM Season WHERE id = '${season.id}'`, client, [season.id])
    // NOTE qac: only get the latest season!
    // const seasons = await sqlQuery(`SELECT * FROM Season WHERE gameInstanceId = '${gameInstances[0].id}' ORDER BY createdAt DESC LIMIT 1`, client);
    const segments = await sqlQuery(`SELECT * FROM Segment WHERE seasonId IN (${seasons.map((s) => s.id).join(", ")})`, client)
    const periods = await sqlQuery(`SELECT * FROM Period WHERE segmentId IN (${segments.map((s) => s.id).join(", ")})`, client)
    const mapping = {
      gameInstances,
      seasons,
      segments,
      periods,
    }
    Object.keys(mapping).forEach((key) => mapping[key].forEach(clearRowDbAttrs))
    this.props.history.push(`/admin/game-instances/new?${actionParams("COPY", mapping)}`)
  }
  public onDelete = (mutate, refetch, id) => (event) => {
    // console.log(mutate, id, event)
    event.preventDefault()
    const mapping = {
      GameInstance: {
        associations: {
          Season: {
            associations: {
              Segment: {
                associations: {
                  Period: {},
                },
              },
            },
          },
        },
      },
    }
    mutate({ variables: { mapping, id } }).then(
      (res) => {
        // console.log('success!', res)
        refetch()
      },
      (err) => console.error(err),
    )
  }

  public gameInstancesMap(gameInstance: any, currentData: any, client: any, refetch: any, deleteGameInstance: any) {
    const currentPeriod = gameInstance.currentPeriod || ({} as never)
    const segment = currentPeriod.segment || ({} as never)
    const season = segment.season || ({} as never)
    const allSeasons = gameInstance.seasons.edges.map(edgeToNode)
    const sportType = segment.sportType || "(none)"
    const realSeasons = currentData.filter((ss) => ss.sportType === sportType)
    const names = realSeasons.map(seasonToName)
    const avail = unique(names)
    const pastNames = allSeasons.map((ss) => seasonToName({ year: ss.year, seasonType: ss.season }))
    const newSeasons = realSeasons.filter((ss) => Date.parse(ss["MIN(startsAt)"]) > (currentPeriod.endsAt || 1) && !pastNames.includes())
    const isNotComingSoonState = !!(currentPeriod.isCurrent && currentPeriod.isPickable)
    // console.dir(avail)
    // console.dir(newSeasons)
    // console.dir(realSeasons)
    // console.dir(currentPeriod)
    // console.dir(segment)
    return (
      <TableRow key={gameInstance.id}>
        <TableCell>
          <ButtonLink to={withoutDomain(segment.landingUrl || "")}>{gameInstance.uid}</ButtonLink>
        </TableCell>
        <TableCell>{segment.gameType || "(none)"}</TableCell>
        <TableCell>{sportType}</TableCell>
        <TableCell>
          <Tooltip
            title={
              <Typography variant="caption">
                Available: {avail.join(", ")}
                <br />
                Past: {pastNames.join(", ")}
              </Typography>
            }
          >
            <Typography variant="inherit">{`${season.year || "none"} ${season.season || "none"}`}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{currentPeriod.description || "(none)"}</TableCell>
        <TableCell>{isNotComingSoonState ? "Pickable" : "Coming Soon"}</TableCell>
        <TableCell>
          <SimpleMenu>
            {newSeasons.map((ss, i) => {
              return (
                <MenuItem key={i} onClick={this.rolloverClick(gameInstance, ss, client)}>
                  Rollover {ss.year} {ss.seasonType}
                </MenuItem>
              )
            })}
            {pastNames.map((ss, i) => {
              return (
                <MenuItem key={i} onClick={this.editClick(gameInstance, ss.id, client)}>
                  Edit {ss.year} {ss.season}
                </MenuItem>
              )
            })}
            {currentPeriod.id && (
              <MenuItem color="primary" onClick={this.comingSoonClick(isNotComingSoonState, currentPeriod, client, refetch)}>
                {`${isNotComingSoonState ? "Activate Coming Soon" : "Deactivate Coming Soon"}`}
              </MenuItem>
            )}
            <MenuItem color="primary" onClick={this.copyClick(gameInstance, client)}>
              Duplicate
            </MenuItem>
            <MenuItem color="secondary" onClick={this.onDelete(deleteGameInstance, refetch, gameInstance.id)}>
              Delete
            </MenuItem>
          </SimpleMenu>
        </TableCell>
      </TableRow>
    )
  }

  public render() {
    // console.dir(this.props)
    // console.dir(this.state)
    const { theme, classes } = this.props
    // console.dir(classes)
    if (!theme) {
      return null
    }
    return (
      <Mutation mutation={ADMIN_DELETE_GAME_INSTANCE_MUTATION}>
        {(deleteGameInstance) => (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>UID</TableCell>
                  <TableCell>Game Type</TableCell>
                  <TableCell>Sport</TableCell>
                  <TableCell>Current Season</TableCell>
                  <TableCell>Current Period</TableCell>
                  <TableCell>Pickable</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <Query query={ADMIN_GAME_INSTANCES_QUERY}>
                  {({ loading, error, data, refetch, client }: IQueryResult) => {
                    // console.dir(data);
                    if (loading || deleteGameInstance.loading || this.state.working || !data) {
                      return (
                        <TableRow>
                          <TableCell colSpan={6} scope="row" align="center" className={classes.progress}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )
                    }
                    if (error) {
                      return (
                        <TableRow>
                          <TableCell colSpan={6} scope="row">
                            {`Error! ${error!.message}`}
                          </TableCell>
                        </TableRow>
                      )
                    }
                    const currentData = data.sql
                    const gameInstances = data.gameInstances.edges.map(edgeToNode)
                    if (!gameInstances.length) {
                      return (
                        <TableRow>
                          <TableCell colSpan={6} scope="row">
                            No current rows
                          </TableCell>
                        </TableRow>
                      )
                    }
                    return gameInstances.map((gameInstance) => this.gameInstancesMap(gameInstance, currentData, client, refetch, deleteGameInstance))
                  }}
                </Query>
              </TableBody>
            </Table>
            <Fab color="secondary" className={classes.fab} component={Link} to={`/admin/game-instances/new`}>
              <AddIcon />
            </Fab>
          </Paper>
        )}
      </Mutation>
    )
  }
}

const GameInstanceListWithStyles = withRouter(withStyles(styles, { withTheme: true })(GameInstanceList))

export default () => (
  <Layout title="Game Instances">
    <GameInstanceListWithStyles />
  </Layout>
)
